// Colors
$color-primary: #353D56;//223b59
$color-secondary: #8C95B6;//4bc1bd
$color-tertiary: #7f2346;

$color-text: #494c4d;
$color-text-gray: #ddd;
$color-gray-light: #e6e6e6;
$color-gray-light2: #f1f1f1;
$color-black: #000;
$color-white: #fff;
$color-gray: #434343;

$color-danger: #EC6950;
$color-success: #C2E184;
$color-disabled: #888;
$color-skyblue: #42A9D6;
$color-taskborder: #BFBFBF;
$color-tasktext: #707070;

$header-shadow: 0 4px 10px -6px $color-black;

$footer-shadow: 0px -5px 2px 0px $color-text-gray;
$active-footer-shadow: 0px -5px 2px 0px $color-text-gray;

// Footer menu vars
$color-footer-border: $color-secondary;

$vertical-offset: 14px;
$menu-height: 60px;
$menu-width: 60px;

// Messaging
$messaging-text: #414042;
$messaging-box-bg:#E9E9E9;
$messaging-box-border:#B2B2B2;
$messaging-input-icon-color: $color-text-gray;
$messaging-attachement-bg: #f5f5f5;

// Backdrop
$bg-backdrop: rgba($color-black, .5);

// Datepicker
$datepicker-max-width: 280px;

// New Message Icon
$new-message-icon-top-pos: -6px;
$new-message-icon-right-pos: -5px;
